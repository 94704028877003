/** @format */
/*eslint-disable */
import config from './config';

// Track images
import Track1 from './assets/images/tracks/1.png';
import Track2 from './assets/images/tracks/2.png';
import Track3 from './assets/images/tracks/3.png';
import Track4 from './assets/images/tracks/4.png';
import Track5 from './assets/images/tracks/5.png';
import Track6 from './assets/images/tracks/6.png';

// Parnter logos
import CarryLogo from './assets/images/partners/carry.jpg';

export const API = {
    LOGIN: `${config.API_BASE}/auth/login/`,
    REGISTER: `${config.API_BASE}/auth/register/`,
    SETUPACCOUNT: `${config.API_BASE}/setup-account`,
    USER: `${config.API_BASE}/user/`,
    PROFILE: `${config.API_BASE}/profile/`,
    PROFILE_VIEWS: `${config.API_BASE}/profile_views/`,
    SERVICECATEGORIES: `${config.API_BASE}/service-categories`,
    SESSION: `${config.API_BASE}/session/`,
    S3: `${config.API_BASE}/s3`,
    PAYMENT: `${config.API_BASE}/payment/`,
    PASSWORD_RESET: `${config.API_BASE}/password_reset/`,
    THREAD: `${config.API_BASE}/thread/`,
    MESSAGE: `${config.API_BASE}/message/`,
    PRODUCT: `${config.API_BASE}/product/`,
    BLOG: `${config.API_BASE}/blog/`,
    AUTHOR: `${config.API_BASE}/author/`,
    NOTIFICATION: `${config.API_BASE}/notification/`,
    ATTENDEE: `${config.API_BASE}/attendee/`,
    GIFT_CARD: `${config.API_BASE}/gift_card/`,
    FOLDER: `${config.API_BASE}/folder/`,
    EVENT: `${config.API_BASE}/event/`,
    INVITE: `${config.API_BASE}/invite/`,
    NOTE: `${config.API_BASE}/note/`,
    LEDGER: `${config.API_BASE}/ledger/`,
    RESOURCE: `${config.API_BASE}/resource/`,
    SHARE: `${config.API_BASE}/share/`,
    SIGNATURE: `${config.API_BASE}/signature/`,
    PAYMENT_PLAN: `${config.API_BASE}/payment_plan/`,
    VERSION: `${config.API_BASE}/latest-version/`,
    LOCATION: `${config.API_BASE}/location/`,
}

export const ACCOUNT_TYPES = {
    EXPERT: 'expert',
    PARENT: 'parent',
}

export const PRODUCT_TYPES = {
    SINGLE: 'single',
    GROUP: 'group',
    SUPPORT: 'support',
    RESOURCE: 'resource',
    TECH: 'tech',
    PRODUCT: 'product',
    PACKAGE: 'package',
}

export const PRODUCT_TYPES_FRIENDLY = {
    SINGLE: 'Live 1-on-1 Session',
    GROUP: 'Live Group Session',
    SUPPORT: 'Support Group',
    RESOURCE: 'On-Demand Resource',
    TECH: 'Tech Solution',
    PRODUCT: 'Physical Product',
    PACKAGE: 'Package',
}

export const PRODUCT_TYPES_ABBREVIATED = {
    'single': '1-on-1',
    'group': 'Group',
    'support': 'Support Group',
    'tech': 'Tech',
    'product': 'Product',
    'resource': 'Resource',
    'package': 'Package',
}

export enum PaywallTypes {
    BASE = 'base',
    ADD_ON = 'add_on',
}

export enum OnboardingSteps {
    TRAINING_VIDEO = 'training_video',
    ONBOARDING_SURVEY = 'onboarding_survey',
    LISTING_PAGE = 'listing_page',
    CONNECT_BANK = 'connect_bank',
    INVITE_CLIENT = 'invite_client',
    ADD_SINGLE_PRODUCT = 'add_single_product',
    ADD_GROUP_PRODUCT = 'add_group_product',
    ADD_RESOURCE_PRODUCT = 'add_resource_product',
    SOCIAL_ANNOUNCEMENT = 'social_announcement',
    SHARE_PROFILE = 'share_profile',
    SHARE_PRODUCT = 'share_product',
    TRAINING_DOCUMENTATION = 'training_documentation',
    AGREED_TO_TOS = 'agreed_to_tos',
}

export const ORDERED_ONBOARDING_STEPS = [
    // OnboardingSteps.TRAINING_VIDEO,
    OnboardingSteps.ONBOARDING_SURVEY,
    OnboardingSteps.LISTING_PAGE,
    OnboardingSteps.TRAINING_DOCUMENTATION,
    OnboardingSteps.AGREED_TO_TOS,
    OnboardingSteps.CONNECT_BANK,
    OnboardingSteps.INVITE_CLIENT,
    OnboardingSteps.ADD_SINGLE_PRODUCT,
    OnboardingSteps.SOCIAL_ANNOUNCEMENT,
    OnboardingSteps.ADD_GROUP_PRODUCT,
    // OnboardingSteps.ADD_RESOURCE_PRODUCT,
    // OnboardingSteps.SHARE_PROFILE,
    // OnboardingSteps.SHARE_PRODUCT,
];

export const CANCELATION_POLICIES = [
    {
        value: 'flexible',
        title: 'Flexible',
        description: 'Cancellations made after the session start time may be eligible for a full or partial refund, according to the hosting Expert’s individual discretion. No-shows will be charged at 25% of the total session fee.',
    },
    {
        value: 'moderate',
        title: 'Moderate',
        description: 'Cancellations within 12 hours of the session may be eligible for a full or partial refund, according to the hosting Expert’s individual discretion. No-shows will be charged at the full rate.',
    },
    {
        value: 'strict',
        title: 'Strict',
        description: 'Cancellations within 24 hours of the session will not be eligible for a refund. No-shows will be charged at the full rate.',
    },
];

export const TRACKS = [
    {
        value: 'working',
        title: 'Working Parents',
        description: 'Resources to navigate the challenges of working and parenting or returning to work.',
        image: Track1,
    },
    {
        value: 'conceive',
        title: 'Trying to Conceive',
        description: 'Resources for parents navigating conception, fertility, adoption or surrogacy.',
        image: Track2,
    },
    {
        value: 'pregnant',
        title: 'Pregnant / Expecting',
        description: 'Pregnancy and birth resources for parents who are expecting.',
        image: Track3,
    },
    {
        value: 'babies',
        title: 'First Year Postpartum',
        description: 'Resources for newborn care, postpartum and babies.',
        image: Track4,
    },
    {
        value: 'kids',
        title: 'Parents of Young Kids',
        description: 'Resources for parents of toddlers and young children.',
        image: Track5,
    },
    {
        value: 'teens',
        title: 'Parents of Teens & Tweens',
        description: 'Resources for parents navigating the challenging time of kids as they become tweens and teens.',
        image: Track6,
    },
];


// TODO: Finish this
export const PARTNERS = [
    {
        name: 'Carry',
        description: 'Carry offers prenatal and postpartum yoga and meditation for all on this journey. Via a simple and beautiful app.',
        url: 'https://www.thecarryapp.com',
        tags: [
            'Movement and Fitness',
            'Labor and Birth',
            'Prenatal Care',
            'Mental Wellness / Life Design',
        ],
        discountDetails: 'After downloading the app, scroll down on the pricing screen, tap "Redeem Code" and enter "Carry3Nessle" for 3 days to look around the app for free. After 3 days, you STILL have a free one week trial on the App Store when you choose a subscription! Great for pregnancy and post, whether you have yoga or meditation experience or not.',
        image: CarryLogo,
    },
]
