import axios from 'axios';
import { store } from '../store';
import { LOGOUT } from '../store/actions';
import history from './history';

// Create axios instance with base config
const axiosInstance = axios.create({
  // ... your base config
});

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      // Dispatch logout action
      store.dispatch({ type: LOGOUT });
      
      // Redirect to login page
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance; 